/* eslint-disable react-hooks/rules-of-hooks */
'use client';
import { usePathname as nextUsePathname, useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { APP } from '@peloton/app-config';

export const useHistoryMethod = () => {
  const { push, replace } = useRouter();
  return { push, replace };
};
// NOTE: Because we are sharing these hooks across Page and App router Apps we need to disable the hook conditional rule because there is no chance the hooks will be called incorrectly within each app.
export const useQueryParams = () => {
  // useRouter is not compatible with Next.js App Router (which next-account uses)
  if (APP === 'next-account') {
    const searchParams = useSearchParams();
    return useMemo(() => searchParams, [searchParams]);
  }
  const { query } = useRouter();
  return useMemo(() => query, [query]);
};

export const usePathname = () => {
  // useRouter is not compatible with Next.js App Router (which next-account uses)
  if (APP === 'next-account') {
    const pathname = nextUsePathname();
    return pathname;
  }
  const { pathname } = useRouter();
  return pathname;
};
